<template>
  <div>
    <div class="total-info">
      <div class="total-item blue-item">
        <span>今日交易额：</span>
        <span>{{ statisticsInfo.amount || 0 }}</span>
      </div>
      <div class="total-item green-item">
        <span>今日交易笔数：</span>
        <span>{{ statisticsInfo.sum || 0 }}</span>
      </div>
      <div class="total-item green-item">
        <span>今日服务人数：</span>
        <span>{{ statisticsInfo.userCount || 0 }}</span>
      </div>
      <div class="total-item blue-item">
        <span>今日客单价：</span>
        <span>{{ statisticsInfo.price || 0 }}</span>
      </div>
    </div>
    <div class="table-page-title">
      <div class="fl">
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-col>
            <el-form-item label="" prop="departmentId">
              <el-select class="small-input" @change="handleChangeDepartment" v-model="searchForm.departmentId"
                         placeholder="请选择医院">
                <el-option
                        v-for="item in departmentList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="building">
              <el-select clearable class="small-input" v-model="searchForm.building" placeholder="请选择楼宇" @change="changeBuilding">
                <el-option
                        v-for="item in buildingList"
                        :key="item.building"
                        :label="item.building"
                        :value="item.building">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="floor">
              <el-select clearable class="small-input" v-model="searchForm.floor" placeholder="请选择楼层" @change="changeFloor">
                <el-option
                        v-for="item in floorList"
                        :key="item.floor"
                        :label="item.floor"
                        :value="item.floor">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="place">
              <el-select clearable class="small-input" v-model="searchForm.place" placeholder="请选择位置" @change="changePlace">
                <el-option
                        v-for="item in placeList"
                        :key="item.place"
                        :label="item.place"
                        :value="item.place">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-form-item label="" prop="orderNo">
            <el-input class="small-input" v-model="searchForm.orderNo" placeholder="订单号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="department">
            <el-input class="small-input" v-model="searchForm.department" placeholder="医院名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="status">
            <el-select clearable class="small-input" v-model="searchForm.status" placeholder="订单状态">
              <el-option
                      v-for="item in statusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="deviceId">
            <el-input class="small-input" v-model="searchForm.deviceId" placeholder="设备ID"></el-input>
          </el-form-item>
          <el-form-item label="" prop="name">
            <el-input class="small-input" v-model="searchForm.name" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="telephone">
            <el-input class="small-input" v-model="searchForm.telephone" placeholder="手机号"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="fl search-form-btn">
        <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button class="fl" size="small" @click="reset()">重 置</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="订单号"
            prop="orderNo"
            fixed="left"
            align="center"
            header-align="center"
            width="160"
        >
          <template slot-scope="scope">
            <span class="link_word" @click="goDetail(scope.row.orderNo)" style="overflow:hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;       white-space: normal;">{{ scope.row.orderNo || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="下单时间"
            prop="createTime"
            width="180"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="租赁时间"
                prop="pickTime"
                width="180"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="归还时间"
                prop="returnTime"
                width="180"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备ID"
            prop="deviceId"
            width="180"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.deviceId || '-' }}</span>
          </template>
        </el-table-column>
        <!--<el-table-column
            label="设备IMEI"
            prop="imei"
            show-overflow-tooltip="true"
            width="180"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span class="link_word" @click="goDeviceDetail(scope.row.deviceId)">{{ scope.row.imei || '-' }}</span>
          </template>
        </el-table-column>-->
        <el-table-column
            label="医院名称"
            align="center"
            header-align="center"
            show-overflow-tooltip="true"
            width="180"
            prop="department">
          <template slot-scope="scope">
            <span>{{ scope.row.department || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
                label="楼层位置"
                prop="floor"
                align="center"
                show-overflow-tooltip="true"
                width="150"
                header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.floor || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="用户名"
            prop="userName"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.userName || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="手机号"
            prop="telephone"
            width="120"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="订单状态"
            prop="status"
            align="center"
            header-align="center"
            width="120"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.status === 0 ? '下单待支付' : row.status === 1 ? '已支付待取货' : row.status === 2 ? '租赁中' : row.status === 3 ? '已归还待确认' : row.status === 4 ? '已确认待结算' : row.status === 5 ? '归还结算完成' : row.status === 6 ? '订单关闭' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="押金金额"
            prop="depositAmount"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="租金金额"
            prop="rentalAmount"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="押金状态"
            prop="depositStatus"
            align="center"
            width="120"
            header-align="center"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.depositStatus === 0 ? '待支付押金' : row.depositStatus === 1 ? '已支付押金' : row.depositStatus === 2 ? '待退还押金' : row.depositStatus === 3 ? '已返还押金' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="租金状态"
            prop="rentalStatus"
            align="center"
            width="120"
            header-align="center"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.rentalStatus === 0 ? '未开始计费' : row.rentalStatus === 1 ? '开始租金计费' : row.rentalStatus === 2 ? '待支付租金' : row.rentalStatus === 3 ? '已支付租金' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="货道编号"
            prop="wayNo"
            align="center"
            header-align="center"
        >
        </el-table-column>
        <el-table-column
            label="货道状态"
            prop="wayNoStatus"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
          {{ row.wayNoStatus === 0 ? '可租赁' : row.wayNoStatus === 1 ? '已分配' : row.wayNoStatus === 2 ? '租赁中' : row.wayNoStatus === 3 ? '归还中' : row.wayNoStatus === 4 ? '消毒中' : row.wayNoStatus === 5 ? '冷却中' : row.wayNoStatus === 6 ? '不可用' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="锁状态"
            prop="doorStatus"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{row.doorStatus === 1 ? '锁开' : row.doorStatus === 0 ? '锁关' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="货状态"
            prop="hasGoods"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{row.hasGoods === 0 ? '无床' : row.hasGoods === 1 ? '有床' : row.hasGoods === 2 ? 'RFID异常' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="退款申请"
            width="100"
            prop="refund"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
           {{ row.refund ? '有' : '无' }}
          </template>
        </el-table-column>
        <el-table-column
            label="订单异常"
            prop="abnormal"
            width="100"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.abnormal ? '有' : '无' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
import {setMenuList} from "@/assets/js/common";
export default {
  name: "Chairorder",
  mixins:[tableUse],
  computed:{
    deviceId(){
      return this.$route.params.deviceId
    },
    department(){
      return this.$route.params.department
    },
    name(){
      return this.$route.params.name
    }
  },
  data(){
    return {
      searchForm:{
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:null,
        deviceType: 1,
        departmentId: null,
        building: '',
        floor: '',
        place: '',
        positionId:''
      },
      statusList:[
        {
          value:0,
          label:'下单待支付'
        },
        {
          value:1,
          label:'已支付待取货'
        },
        {
          value:2,
          label:'租赁中'
        },
        {
          value:3,
          label:'已归还待确认'
        },
        // {
        //   value:4,
        //   label:'已确认待结算'
        // },
        {
          value:5,
          label:'归还结算完成'
        },
        {
          value:6,
          label:'订单关闭'
        }
      ],
      deviceTypes:[
        {
          value:0,
          label:'陪护床'
        },
        {
          value:1,
          label:'轮椅'
        }
      ],
      tableData:[
      ],
      statisticsInfo:{},
      departmentList:[],
      buildingList:[],
      floorList:[],
      placeList:[],
      positionList:[]
    }
  },
  methods:{
    reset(){
      this.searchForm = {
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:null,
        deviceType:1,
        departmentId: null,
        building: '',
        floor: '',
        place: '',
        positionId:''
      }
      this.search();
    },
    goDetail(orderNo){
      setMenuList({
        path:'/chairOrderDetail',
        name:'订单详情'
      })
      this.$router.push({name:'ChairOrderDetail',query:{orderNo}})
    },
    goDeviceDetail(deviceId){
      this.$router.push({name:'DeviceDetail',query:{id:deviceId}})
    },
    searchByDeviceId(deviceId){
      this.searchForm = {
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:'',
        deviceType:1,
        departmentId: null,
        building: '',
        floor: '',
        place: '',
        positionId:''
      }
      this.searchForm.deviceId = deviceId;
      this.search();
    },
    searchByCompany(department){
      this.searchForm = {
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:''
      }
      this.searchForm.department = department;
      this.search();
    },
    searchByUserName(userName){
      this.searchForm = {
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:''
      }
      this.searchForm.name = userName;
      this.search();
    },
    getStatisticsInfo(){
      this.$axios.get("/sys-api/order-api/getQuantity",{deviceType:1},(res) => {
        if(res.code === '100'){
          this.statisticsInfo = res.data;
        }
      })
    },
    getTableData(){
      this.tableLoading = true;
      this.searchForm.pageNum = this.pageNum;
      this.searchForm.pageSize = this.pageSize;
      this.searchForm.positionName = this.searchForm.building + this.searchForm.floor + this.searchForm.place;
      /*let formData = {
        pageNum:this.pageNum,
        pageSize: this.pageSize
      }
      if(this.searchForm.deviceId) formData.deviceId = this.searchForm.deviceId;
      if(this.searchForm.name) formData.name = this.searchForm.name;
      if(this.searchForm.telephone) formData.telephone = this.searchForm.telephone;
      if(this.searchForm.orderNo) formData.orderNo = this.searchForm.orderNo;
      if(this.searchForm.department) formData.department = this.searchForm.department;
      if(this.searchForm.status || this.searchForm.status === 0) formData.status = this.searchForm.status;
      if(this.searchForm.deviceType || this.searchForm.deviceType === 0) formData.deviceType = this.searchForm.deviceType*/
      this.$axios.post("/sys-api/order-api/orderPage",this.searchForm,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    },
    getDepartmentList(){
      this.$axios.get("/sys-api/site-api/getDepartment",{},(res) => {
        if(res.code === '100'){
          this.departmentList = res.data;
        }
      })
    },
    handleChangeDepartment(){
      this.searchForm.positionId = '';
      this.searchForm.building = "";
      this.searchForm.floor = "";
      this.searchForm.place = "";
      this.getPositionList();
    },
    getPositionList(){
      this.$axios.get("/sys-api/site-api/getPosition",{
        departmentId:this.searchForm.departmentId
      },(res) => {
        if(res.code === '100'){
          this.getBuildingList(res.data || []);
          //this.getFloorList(res.data || []);
          //this.getPlaceList(res.data || []);
          this.positionList = res.data || [];
        }
      })
    },
    changeBuilding(){
      this.searchForm.floor = "";
      this.searchForm.place = "";
      this.getFloorList(this.positionList);
    },
    changeFloor(){
      this.searchForm.place = "";
      this.getPlaceList(this.positionList);
    },
    changePlace(){
    },
    getBuildingList(arr){
      this.buildingList = [];
      arr.forEach((item) => {
        if(this.buildingList.every((exist) => {return item.building !== exist.building})){
          this.buildingList.push(item);
        }
      })
    },
    getFloorList(arr){
      this.floorList = [];
      if(this.searchForm.building) arr = arr.filter((item) => {return item.building === this.searchForm.building});
      arr.forEach((item) => {
        if(this.floorList.every((exist) => {return item.floor !== exist.floor})){
          this.floorList.push(item);
        }
      })
    },
    getPlaceList(arr){
      this.placeList = [];
      if(this.searchForm.building) arr = arr.filter((item) => {return item.building === this.searchForm.building});
      if(this.searchForm.floor) arr = arr.filter((item) => {return item.floor === this.searchForm.floor});
      arr.forEach((item) => {
        if(this.placeList.every((exist) => {return item.place !== exist.place})){
          this.placeList.push(item);
        }
      })
    },
  },
  mounted() {
    if(this.deviceId) this.searchForm.deviceId = this.deviceId;
    if(this.department) this.searchForm.department = this.department;
    if(this.name) this.searchForm.name = this.name;
    this.getStatisticsInfo();
    this.getTableData();
    this.getDepartmentList();
  }
}
</script>

<style scoped lang="scss">
  .table-container {
    height: calc(100% - 185px);
  }

</style>
